<template>
  <div id="home">
    <van-nav-bar fixed title="美国校园疫情" left-text="返回" left-arrow @click-left="onClickLeft"></van-nav-bar>
    <div id="scroll">
      <van-tabs
        v-model="active"
        :border="true"
        :offset-top="46"
        sticky
        scrollspy
        swipeable
        title-active-color="#4169e2"
        color="#4169e2"
        background="#f3f3f3"
      >
        <van-tab title="疫情数据">
          <div class="refCon countWrap">
            <Count from="covid"></Count>
            <client-only>
              <Map></Map>
            </client-only>
          </div>
          <client-only>
            <MapLine></MapLine>
          </client-only>
        </van-tab>
        <van-tab title="学校列表">
          <div class="schoolWrap">
            <top-header :padding="true" title="重点关注--学校"></top-header>

            <!-- <search></search> -->
            <school-table from="covid"></school-table>
          </div>
        </van-tab>

        <van-tab title="州列表">
          <div class="stateWrap">
            <top-header :padding="true" title="重点关注--州"></top-header>
            <state-table></state-table>
          </div>
        </van-tab>
        <van-tab title="热点新闻">
          <News></News>

          <More></More>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import {
  Count,
  Map,
  Video,
  Tab,
  Banner,
  News,
  Notice,
  Focus,
  MyOrder,
  MapLine,
  Search,
  Pie,
  NewsTag,
  Visa,
  AllSite,
  Tips,
  Welfare,
  More
} from '../item';
import AdItem from '@/components/AdItem';
import Sticky from '@/components/Sticky';
import TopHeader from '@/components/TopHeader';
import SchoolTable from '../components/SchoolTable';
import StateTable from '../components/StateTable';
import { mapState, mapGetters } from 'vuex';
import { backInitMixin } from '@/utils/mixin';
export default {
  name: 'Covid',

  components: {
    Banner,
    Search,
    Count,
    Video,
    Map,
    More,
    Tab,
    News,
    Notice,
    Focus,
    MyOrder,
    MapLine,
    AdItem,
    Sticky,
    Pie,
    NewsTag,
    Visa,
    AllSite,
    Tips,
    Welfare,
    TopHeader,
    SchoolTable,
    StateTable
  },

  mixins: [backInitMixin],
  data() {
    return {
      active: 0
    };
  },
  created() {},
  mounted() {},

  computed: {
    ...mapState({
      ad: state => state.app.ad,
      loginId: state => state.user.loginId
    }),
    ...mapGetters(['group']),
    imgUrl() {
      if (this.loginId) {
        return `https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403?email=${this.loginId}`;
      } else {
        return 'https://erp.wholerengroup.com/wholeren-service-questionnaire/-7025418009546371403';
      }
    }
  },

  methods: {
    handleMap(index) {
      this.active = index;
    }
  }
};
</script>

<style scoped lang="less">
#home {
  padding-top: 46px;
  .stateWrap {
    margin-top: 20px;
  }
  .countWrap {
    position: relative;
    background: #f3f3f3;
    overflow: hidden;
  }

  .report {
    display: block;

    img {
      width: 100%;
    }
  }
}
</style>
