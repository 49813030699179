import { render, staticRenderFns } from "./Covid.vue?vue&type=template&id=5fe34a04&scoped=true&"
import script from "./Covid.vue?vue&type=script&lang=js&"
export * from "./Covid.vue?vue&type=script&lang=js&"
import style0 from "./Covid.vue?vue&type=style&index=0&id=5fe34a04&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe34a04",
  null
  
)

export default component.exports